
import {
    computed, defineComponent, nextTick, onBeforeMount, onBeforeUnmount, reactive,
} from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import coreStore from '@/store/CoreStore';
import Screen from '@/components/layout/Screen.vue';
import Transaction from '@/domain/Transaction';
import Item from '@/domain/Item';
import { ItemTableData } from '@/domain/ItemTableData';
import SupplierShippingTransactionHead from '@/modules/partner-engagement/view/supplier-shipping/components/SupplierShippingTransactionHead.vue';
import RouteConfigService from '@/services/RouteConfigService';
import SupplierShipmentService from '@/services/SupplierShipmentService';
import { useNotification } from '@/composable/useNotifications';
import PartnerEngagementRouteTypes from '@/modules/partner-engagement/routes/types';
import Shipment from '@/domain/Shipment';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import ItemService from '@/services/ItemService';
import TagListener from '@/modules/floortrak/services/TagListener';
import TrackedItem from '@/domain/TrackedItem';
import TrackedItemTagModal from '@/components/TrackedItemTagModal.vue';
import TransactionFooter from '@/components/TransactionFooter.vue';
import NumberInput from '@/components/inputs/NumberInput.vue';
import useSupplierShipping from '@/composable/useSupplierShipping';
import TransactionState from '@/interfaces/TransactionState';
import TransactionQuantityNumberInput from '@/components/TransactionQuantityNumberInput.vue';
import BTable, { BTableField } from '@/components/bootstrap-library/table/BTable/BTable.vue';
import TransactionSaveShipNowButtons from '@/components/buttons/TransactionSaveShipNowButtons.vue';
import { ItemQtyKey, MaxTrailerProps, useMaxTrailerCompute } from '@/composable/useMaxTrailerCompute';
import useUnitLoadGrouping from '@/composable/useUnitLoadGrouping';
import { Formatter, TransactionStatus } from '@/domain/TransactionStatus';
import TrailerType from '@/domain/TrailerType';
import ItemQuantity from '@/interfaces/ItemQuantity';
import SupplierShippingTagScanHandler from '@/services/tag-scanning/scan-handler/SupplierShippingTagScanHandler';
import NonWorkDayService from '@/services/NonWorkDayService';
import { ItemType } from '@/domain/enums/ItemType';
import useStringFormatter from '@/composable/useStringFormatter';
import Thumbnail from '@/components/Thumbnail.vue';
import ItemPicker from '@/components/ItemPicker.vue';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';

interface State extends TransactionState {
    tableData: Array<ItemTableData>;
    tableDataWithPossibleUnitLoadGrouping: Array<ItemQuantity | ItemQuantity[]>;
    itemsInConfig: Array<Item>;
    preferredTrailerType: TrailerType;
    showItemSearch: boolean;
}

export default defineComponent({
    name: 'supplier-shipping-transaction-screen',
    components: {
        Thumbnail,
        Screen,
        SupplierShippingTransactionHead,
        TrackedItemTagModal,
        TransactionFooter,
        BTable,
        NumberInput,
        TransactionQuantityNumberInput,
        TransactionSaveShipNowButtons,
        ItemPicker,
    },
    props: {
        modelValue: {
            type: Transaction,
            default: () => new Transaction(),
        },
    },
    setup(props) {
        const { userLocation } = coreStore.getInstance().profileStore;
        const routeConfigService = new RouteConfigService();
        const shipmentService = new SupplierShipmentService();
        const itemService = new ItemService();
        const nonWorkDayService = new NonWorkDayService(coreStore.getInstance().timeSetStore.allTimeSets);

        const { capitalizeFirstLetter } = useStringFormatter();

        const state = reactive<State>({
            shipment: new Shipment(),
            transaction: new Transaction(),
            originalShipmentStringified: '',
            loading: false,
            tableData: [],
            saving: false,
            shipping: false,
            itemsInConfig: [],
            preferredTrailerType: new TrailerType(),
            tableDataWithPossibleUnitLoadGrouping: [],
            showItemSearch: false,
        });

        const transaction = computed((): Transaction => state.transaction as Transaction);
        const tableData = computed((): Array<ItemTableData> => state.tableData as Array<ItemTableData>);
        const tagHandler = new SupplierShippingTagScanHandler(transaction, tableData);

        const doesFromLocationMatchUserLocation = computed(() => state.transaction.fromLocationId === userLocation.id);
        const isPlannedQuantityFieldDisabled = computed((): boolean => !doesFromLocationMatchUserLocation.value || state.transaction.status > TransactionStatus.PLANNED);
        const isActualQuantityFieldDisabled = computed((): boolean => !doesFromLocationMatchUserLocation.value || state.transaction.status >= TransactionStatus.HELD);
        const isReceivedQuantityFieldDisabled = computed((): boolean => !(state.transaction.toLocationId === userLocation.id && state.transaction.status > TransactionStatus.HELD));

        const itemPlannedQuantityChange = (item: Item, qty: number) => {
            state.transaction.setPlannedItemQty(item, qty);
        };

        const itemActualQuantityChange = (item: Item, qty: number) => {
            state.transaction.setActualItemQty(item, qty);

            if (!state.transaction.isExisting) {
                state.transaction.setPlannedItemQty(item, qty);
            }
        };

        const itemReceivedQuantityChange = (item: Item, qty: number) => {
            state.transaction.setReceivedQuantity(item, qty);
        };

        const preferredTrailerType = computed((): TrailerType => new TrailerType(state.shipment.trailerType));

        const canShipNow = computed((): boolean => state.transaction.canShipNow(userLocation));

        const itemList = computed(
            (): Array<ItemTableData> => {
                const items = state.tableData.map((row) => row.item);
                return ItemTableData.BuildSupplierShipmentTableData(items, state.transaction as Transaction);
            },
        );

        const itemKey = computed(
            (): ItemQtyKey => {
                if (state.transaction.status < TransactionStatus.PICKED) {
                    return 'plannedQuantity';
                }
                return 'actualQuantity';
            },
        );

        const showAddContainer = computed(() => {
            const hasNotShipped = !state.transaction.hasShipped;
            const isRouteLoaded = state.transaction.toLocationId && state.transaction.fromLocationId;
            return hasNotShipped && isRouteLoaded && !isPlannedQuantityFieldDisabled.value;
        });

        const maxTrailerProps: MaxTrailerProps = {
            itemList,
            trailerType: preferredTrailerType,
            itemQtyKey: itemKey,
        };

        const maxTrailerComposable = useMaxTrailerCompute(maxTrailerProps);

        const {
            goToTransactionList,
            isDirty,
            shipNow,
            updateExistingShipment,
            validateShipmentForm,
            validationShipmentResult,
            validateTransactionForm,
            validationTransactionResult,
        } = useSupplierShipping({
            transactionListRouteName: PartnerEngagementRouteTypes.TRANSACTION.LISTALL,
            state: (state as unknown) as TransactionState,
            formValidationKeyForShipments: 'pem-supplier-shipping',
            formValidationKeyForTransactions: 'transaction-outbound',
        });

        const { confirm } = useDialogBox();
        const notification = useNotification();

        const { getTransactionLinesInUnitLoadGroupOrder, mapTransactionLinesForTable } = useUnitLoadGrouping();

        async function fetchItemsById(ids: Array<number>): Promise<Array<Item>> {
            if (!ids.length) {
                return [];
            }
            return itemService.getItemsById(ids);
        }

        async function fetchShipmentDetails(transactionId: number) {
            const response = await shipmentService.getShipmentDetailsForTransaction(transactionId);
            if (response.success) {
                state.shipment = new Shipment(response.shipmentDetails);
                state.shipment.fromLocation = props.modelValue?.fromLocation;
            }
        }

        function showItemSearch() {
            state.showItemSearch = true;
        }

        async function addItem(data: { item: Item; quantity: number }) {
            const itemAlreadyAdded = transaction.value.transactionLines.find((line) => line.item.id === data.item.id);
            if (!itemAlreadyAdded) {
                state.tableData.push(new ItemTableData(data.item));
                transaction.value.addEmptyLine(data.item);
            } else {
                notification.showError(`${data.item.name} ${getTranslation('core.common.alreadyAdded')}`);
            }
            state.showItemSearch = false;
        }

        function updateTableData() {
            let tableItems = ItemTableData.BuildSupplierShipmentTableData(state.itemsInConfig, state.transaction as Transaction);
            if (state.transaction.id) {
                tableItems = tableItems.filter((item) => item.actualQuantity != null || item.plannedQuantity != null);
            }
            state.tableData = tableItems;
        }

        function updateTableDataWithUnitLoadGrouping(existingTransaction: Transaction) {
            const transactionLinesWithUnitLoadGrouping = getTransactionLinesInUnitLoadGroupOrder(existingTransaction);

            state.tableDataWithPossibleUnitLoadGrouping = mapTransactionLinesForTable(transactionLinesWithUnitLoadGrouping, existingTransaction.status);
        }

        async function populateTransactionItems(existingTransaction: Transaction) {
            state.loading = true;

            if (!existingTransaction.hasShipped) {
                // load route items
                let items = await routeConfigService.getAllItemsInRouteConfig(existingTransaction.fromLocationId, existingTransaction.toLocationId);

                // Make sure all items on the transaction are still accounted for in the route config
                const routeItemIds = items.map((i) => i.id);
                const missingItemIds = existingTransaction.transactionLines.map((line) => line.item.id).filter((id) => !routeItemIds.includes(id));
                if (missingItemIds.length) {
                    const additionalItems = await fetchItemsById(missingItemIds);
                    items = items.concat(additionalItems);
                }

                state.itemsInConfig = items;
                updateTableData();
            } else {
                updateTableDataWithUnitLoadGrouping(existingTransaction);
            }

            state.loading = false;
        }

        async function initExisting() {
            state.loading = true;
            state.transaction = new Transaction(props.modelValue);
            await fetchShipmentDetails(state.transaction.id);
            await populateTransactionItems(state.transaction as Transaction);
            state.shipment.transactions.push(state.transaction);
            state.originalShipmentStringified = JSON.stringify(state.shipment);
            state.loading = false;
        }

        function initNew() {
            state.transaction.fromLocation = userLocation;
            state.transaction.status = TransactionStatus.PICKED;
            state.transaction.shipDate = nonWorkDayService.getNextWorkingDay(new Date());
            state.originalShipmentStringified = JSON.stringify(state.shipment);
        }

        async function searchItem(barcodes: Array<string>) {
            await tagHandler.performSearch(barcodes);
        }
        const tagListener = new TagListener(searchItem);

        onBeforeMount(async () => {
            tagListener.startListener();

            if (props.modelValue?.isExisting) {
                await initExisting();
            } else {
                initNew();
            }
        });

        onBeforeUnmount(() => {
            tagListener.stopListener();
        });

        const tableFields = computed(
            (): Array<BTableField<Record<string, string>>> => [
                { key: 'imageUrlThumb', label: getTitleCaseTranslation('core.common.image') },
                { key: 'name', label: getTitleCaseTranslation('core.domain.name') },
                { key: 'shortName', label: getTitleCaseTranslation('core.domain.shortName') },
                ...(state.transaction.isExisting
                    ? [
                        {
                            key: 'plannedQuantity',
                            label: getTitleCaseTranslation('core.domain.plannedQuantity'),
                        },
                    ]
                    : []),
                { key: 'actualQuantity', label: getTitleCaseTranslation('core.domain.actualQuantity') },
                ...(state.transaction.tagList.length > 0 ? [{ key: 'tags', label: getTitleCaseTranslation('core.domain.tags') }] : []),
            ],
        );

        function removeTag(tag: TrackedItem) {
            state.transaction.removeTrackedItem(tag);
        }

        async function saveNewShipment() {
            if (validateShipmentForm) {
                validateShipmentForm(state.shipment as Shipment);
            }

            if (validateTransactionForm) {
                validateTransactionForm(state.transaction as Transaction);
            }

            if (validationShipmentResult?.isValid && validationTransactionResult?.isValid) {
                if (await maxTrailerComposable.confirmOverCapacity()) {
                    state.saving = true;

                    state.shipment.clearTransactions();
                    state.shipment.addTransaction(state.transaction as Transaction);

                    const response = await shipmentService.saveNewPickedShipment(state.shipment as Shipment);
                    if (response) {
                        state.originalShipmentStringified = JSON.stringify(state.shipment);
                        goToTransactionList();
                    }
                }
                state.saving = false;
            }
        }

        async function submit() {
            if (state.transaction.isExisting) {
                await updateExistingShipment();
            } else {
                await saveNewShipment();
            }
        }

        async function ship() {
            if (validateShipmentForm) {
                validateShipmentForm(state.shipment as Shipment);
            }

            if (validateTransactionForm) {
                validateTransactionForm(state.transaction as Transaction);
            }

            if (validationShipmentResult?.isValid && validationTransactionResult?.isValid) {
                if (await maxTrailerComposable.confirmOverCapacity()) {
                    await shipNow();
                }
            }
        }

        function itemQuantityChange(rowData: ItemTableData, qty: number, attemptedValue: number) {
            const tagsForItem = state.transaction.getTagsByItem(rowData.item).length;
            if (attemptedValue < tagsForItem) {
                notification.showWarning(capitalizeFirstLetter(getTranslation('core.common.quantityCannotBeLess')));
            } else {
                state.transaction.setSupplierShippingItemQty(rowData.item, qty);
            }
        }

        async function onLocationChanged() {
            if (state.transaction.toLocationId && state.transaction.fromLocationId) {
                populateTransactionItems(state.transaction as Transaction);

                // set inventory category automatically for now to pass validation. will be configurable later
                const response = await routeConfigService.getAllShipFromInventoryCategoriesInRouteConfig(state.transaction.fromLocationId, state.transaction.toLocationId);
                state.transaction.inventoryCategory = response.shift();
            } else {
                state.transaction.clearTransactionLines();
                state.transaction.inventoryCategory = undefined;
                state.itemsInConfig = [];
                state.loading = true;
                nextTick(() => {
                    state.loading = false;
                });
            }
        }

        async function confirmLeave() {
            if (!isDirty.value) {
                return true;
            }
            return confirm({
                title: getTitleCaseTranslation('core.common.areYouSure'),
                message: getTranslation('core.common.allUnsavedDataWillBeLostWhenYouLeaveThisPage'),
            });
        }

        onBeforeRouteLeave(async () => confirmLeave());

        async function goBack() {
            goToTransactionList();
        }

        const navbarTitle = computed((): string => {
            if (state.transaction.id) {
                return getTranslation('core.common.transactionNumberTitle', state.transaction.id, Formatter.GetFriendlyValue(state.transaction.status));
            }

            return getTitleCaseTranslation('core.button.addNewShipment');
        });

        return {
            state,
            goToTransactionList,
            onLocationChanged,
            submit,
            ship,
            isDirty,
            itemQuantityChange,
            goBack,
            removeTag,
            tableFields,
            validationShipmentResult,
            validationTransactionResult,
            userLocation,
            canShipNow,
            maxTrailerComposable,
            navbarTitle,
            showItemSearch,
            addItem,
            ItemType,
            isPlannedQuantityFieldDisabled,
            isActualQuantityFieldDisabled,
            isReceivedQuantityFieldDisabled,
            itemPlannedQuantityChange,
            itemActualQuantityChange,
            itemReceivedQuantityChange,
            showAddContainer,
            getTitleCaseTranslation,
        };
    },
});
