import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74e820ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "transaction-container" }
const _hoisted_2 = { class: "transaction-table-info" }
const _hoisted_3 = { class: "error" }
const _hoisted_4 = {
  key: 0,
  class: "tags-btn"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_supplier_shipping_transaction_head = _resolveComponent("supplier-shipping-transaction-head")!
  const _component_thumbnail = _resolveComponent("thumbnail")!
  const _component_TrackedItemTagModal = _resolveComponent("TrackedItemTagModal")!
  const _component_transaction_quantity_number_input = _resolveComponent("transaction-quantity-number-input")!
  const _component_number_input = _resolveComponent("number-input")!
  const _component_b_table = _resolveComponent("b-table")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_transaction_save_ship_now_buttons = _resolveComponent("transaction-save-ship-now-buttons")!
  const _component_transaction_footer = _resolveComponent("transaction-footer")!
  const _component_screen = _resolveComponent("screen")!
  const _component_item_picker = _resolveComponent("item-picker")!
  const _component_b_modal = _resolveComponent("b-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_screen, {
      id: "supplier-shipping-transaction-screen",
      full: "",
      title: _ctx.navbarTitle,
      "go-back": _ctx.goBack
    }, {
      footer: _withCtx(() => [
        (!_ctx.state.loading)
          ? (_openBlock(), _createBlock(_component_transaction_footer, {
              key: 0,
              transaction: _ctx.state.transaction,
              "max-trailer-composable": _ctx.maxTrailerComposable
            }, {
              actions: _withCtx(() => [
                _createVNode(_component_transaction_save_ship_now_buttons, {
                  "can-edit": !_ctx.modelValue.hasShipped,
                  "can-ship-now": _ctx.canShipNow,
                  "is-dirty": _ctx.isDirty,
                  saving: _ctx.state.saving,
                  shipping: _ctx.state.shipping,
                  transaction: _ctx.state.transaction,
                  onShipNow: _ctx.ship,
                  onSubmit: _ctx.submit
                }, null, 8, ["can-edit", "can-ship-now", "is-dirty", "saving", "shipping", "transaction", "onShipNow", "onSubmit"])
              ]),
              _: 1
            }, 8, ["transaction", "max-trailer-composable"]))
          : _createCommentVNode("", true)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (!_ctx.state.loading)
            ? (_openBlock(), _createBlock(_component_supplier_shipping_transaction_head, {
                key: 0,
                shipment: _ctx.state.shipment,
                transaction: _ctx.state.transaction,
                "shipment-validation-result": _ctx.validationShipmentResult,
                "transaction-validation-result": _ctx.validationTransactionResult,
                style: {"margin-bottom":"25px"},
                onLocationChange: _ctx.onLocationChanged
              }, null, 8, ["shipment", "transaction", "shipment-validation-result", "transaction-validation-result", "onLocationChange"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", null, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.validationTransactionResult.model.transactionLines), 1)
            ])
          ]),
          _createVNode(_component_b_table, {
            "table-key": (_ctx.state.transaction.fromLocationId + _ctx.state.transaction.toLocationId).toString(),
            loading: _ctx.state.loading,
            items: _ctx.state.transaction.hasShipped ? _ctx.state.tableDataWithPossibleUnitLoadGrouping : _ctx.state.tableData,
            fields: _ctx.tableFields
          }, {
            "cell(imageUrlThumb)": _withCtx((row) => [
              _createVNode(_component_thumbnail, {
                "image-url-thumb": row.item.imageUrlThumb,
                "image-url-full": row.item.imageUrlFull
              }, null, 8, ["image-url-thumb", "image-url-full"])
            ]),
            "cell(tags)": _withCtx((row) => [
              (row.item.tags && row.item.tags.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_TrackedItemTagModal, {
                      tags: row.item.tags,
                      title: row.item.shortName,
                      onRemoveTag: _ctx.removeTag
                    }, null, 8, ["tags", "title", "onRemoveTag"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_5))
            ]),
            "cell(plannedQuantity)": _withCtx((row) => [
              (!row.item.isUnitLoad)
                ? (_openBlock(), _createBlock(_component_transaction_quantity_number_input, {
                    key: 0,
                    disabled: _ctx.isPlannedQuantityFieldDisabled,
                    item: row.item,
                    "model-value": row.item.plannedQuantity,
                    "transaction-status": _ctx.state.transaction.status,
                    "hide-stepper": "",
                    onChange: (qty) => _ctx.itemPlannedQuantityChange(row.item.item, qty)
                  }, null, 8, ["disabled", "item", "model-value", "transaction-status", "onChange"]))
                : _createCommentVNode("", true)
            ]),
            "cell(actualQuantity)": _withCtx((row) => [
              (!row.item.isUnitLoad)
                ? (_openBlock(), _createBlock(_component_transaction_quantity_number_input, {
                    key: 0,
                    disabled: _ctx.isActualQuantityFieldDisabled,
                    item: row.item,
                    "model-value": row.item.actualQuantity,
                    "transaction-status": _ctx.state.transaction.status,
                    "hide-stepper": "",
                    onChange: (qty) => _ctx.itemActualQuantityChange(row.item.item, qty)
                  }, null, 8, ["disabled", "item", "model-value", "transaction-status", "onChange"]))
                : _createCommentVNode("", true)
            ]),
            "cell(receivedQuantity)": _withCtx((row) => [
              (!(_ctx.state.transaction.hasShipped && row.item.isUnitLoad))
                ? (_openBlock(), _createBlock(_component_number_input, {
                    key: 0,
                    "model-value": row.item.receivedQuantity,
                    disabled: _ctx.isReceivedQuantityFieldDisabled,
                    style: {"width":"120px","margin":"10px 0px"},
                    onChange: (qty) => _ctx.itemReceivedQuantityChange(row.item.item, qty)
                  }, null, 8, ["model-value", "disabled", "onChange"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_6))
            ]),
            _: 1
          }, 8, ["table-key", "loading", "items", "fields"]),
          (_ctx.showAddContainer)
            ? (_openBlock(), _createBlock(_component_b_button, {
                key: 1,
                class: "add-container",
                variant: "secondary",
                onClick: _ctx.showItemSearch
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.addItem')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["title", "go-back"]),
    _createVNode(_component_b_modal, {
      modelValue: _ctx.state.showItemSearch,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.showItemSearch) = $event)),
      centered: "",
      "hide-footer": "",
      title: _ctx.getTitleCaseTranslation('core.button.addItem')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_item_picker, {
          "item-type": _ctx.ItemType.SHIPPING,
          "allow-quantity-change": false,
          shipping: { fromLocationId: _ctx.state.transaction.fromLocation.id, toLocationId: _ctx.state.transaction.toLocation.id },
          onOnSelect: _ctx.addItem
        }, null, 8, ["item-type", "shipping", "onOnSelect"])
      ]),
      _: 1
    }, 8, ["modelValue", "title"])
  ], 64))
}