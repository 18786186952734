
import { defineComponent, PropType } from 'vue';
import BButton from '@/components/bootstrap-library/BButton.vue';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import Transaction from '@/domain/Transaction';
import useStringFormatter from '@/composable/useStringFormatter';

export default defineComponent({
    name: 'transaction-save-ship-now-buttons',
    components: {
        BButton,
        BSpinner,
    },
    props: {
        canShipNow: {
            type: Boolean,
            default: false,
        },
        canEdit: {
            type: Boolean,
            required: true,
        },
        isDirty: {
            type: Boolean,
            default: true,
        },
        saving: {
            type: Boolean,
            required: true,
        },
        shipping: {
            type: Boolean,
            default: false,
        },
        transaction: {
            type: Object as PropType<Transaction>,
            required: true,
        },
    },
    emits: ['shipNow', 'submit'],
    setup(props, context) {
        const { titleCase } = useStringFormatter();

        function shipNow() {
            context.emit('shipNow');
        }

        function submit() {
            context.emit('submit');
        }

        return {
            shipNow,
            submit,
            titleCase,
        };
    },
});
