import Transaction from '@/domain/Transaction';
import TransactionLine from '@/domain/TransactionLine';
import { TransactionStatus } from '@/domain/TransactionStatus';
import ItemQuantity from '@/interfaces/ItemQuantity';

interface UseUnitLoadGrouping {
    getTransactionLinesInUnitLoadGroupOrder: (transaction: Transaction) => Array<TransactionLine | TransactionLine[]>;
    mapTransactionLinesForTable: (
        lines: Array<TransactionLine | TransactionLine[]>,
        transactionStatus: TransactionStatus
    ) => Array<ItemQuantity | ItemQuantity[]>;
}

export default function useUnitLoadGrouping(): UseUnitLoadGrouping {
    function getTransactionLinesInUnitLoadGroupOrder(transaction: Transaction): Array<TransactionLine | TransactionLine[]> {
        const transactionLineItemIdsWithUnitLoadGrouping = new Map<number, number[]>();
        const transactionLineMap = new Map<number, TransactionLine>();

        transaction.transactionLines.forEach((line) => {
            let parentTransactionLineId: number | undefined;

            if (line.unitLoadParentId) {
                parentTransactionLineId = transaction.transactionLines.find((l) => l.item.id === line.unitLoadParentId)?.id;

                if (parentTransactionLineId) {
                    if (transactionLineItemIdsWithUnitLoadGrouping.has(parentTransactionLineId)) {
                        transactionLineItemIdsWithUnitLoadGrouping.get(parentTransactionLineId)?.push(line.id);
                    } else {
                        transactionLineItemIdsWithUnitLoadGrouping.set(parentTransactionLineId, [line.id]);
                    }
                }
            }

            if (!line.unitLoadParentId || !parentTransactionLineId) {
                transactionLineItemIdsWithUnitLoadGrouping.set(line.id, transactionLineItemIdsWithUnitLoadGrouping.get(line.id) ?? []);
            }

            transactionLineMap.set(line.id, line);
        });

        return Array.from(transactionLineItemIdsWithUnitLoadGrouping.entries()).flatMap((possibleUnitLoadIdGroup) => {
            const transactionLineWithPossibleChildren: (TransactionLine | TransactionLine[])[] = [transactionLineMap.get(possibleUnitLoadIdGroup[0]) as TransactionLine];

            if (possibleUnitLoadIdGroup[1].length > 0) {
                transactionLineWithPossibleChildren.push(possibleUnitLoadIdGroup[1].map((id) => transactionLineMap.get(id) as TransactionLine));
            }

            return transactionLineWithPossibleChildren;
        });
    }

    function setTransactionLineReceivedQuantity(line: TransactionLine, transactionStatus: TransactionStatus): number | null {
        if (transactionStatus === TransactionStatus.DISPUTED && line.receivedQuantity !== line.actualQuantity) {
            return line.receivedQuantity || 0; // return what supplier entered
        }
        // set to same as actual. User can click dispute and then enter values.
        return line.actualQuantity;
    }

    function mapTransactionLinesForTable(
        lines: Array<TransactionLine | TransactionLine[]>,
        transactionStatus: TransactionStatus,
    ): Array<ItemQuantity | ItemQuantity[]> {
        const allTransactionLinesUngrouped: TransactionLine[] = lines.flat();
        const tableRows: Array<ItemQuantity | ItemQuantity[]> = [];

        lines.forEach((line) => {
            if (!Array.isArray(line)) {
                tableRows.push({
                    id: line.item.id,
                    name: line.item.name,
                    shortName: line.item.shortName,
                    height: line.item.height,
                    width: line.item.width,
                    length: line.item.length,
                    quantityRequested: null,
                    actualQuantity: line.actualQuantity,
                    containerVolume: line.item.containerVolume,
                    estimatedQuantity: line.estimatedQuantity,
                    plannedQuantity: line.plannedQuantity,
                    receivedQuantity: setTransactionLineReceivedQuantity(line, transactionStatus),
                    isUnitLoad: line.item.isUnitLoad && allTransactionLinesUngrouped.some((l) => l.unitLoadParentId === line.item.id),
                    unitLoadParentId: line.unitLoadParentId,
                    imageUrlThumb: line.item.imageUrlThumb,
                    imageUrlFull: line.item.imageUrlFull,
                });
            } else {
                tableRows.push(mapTransactionLinesForTable(line, transactionStatus) as ItemQuantity[]);
            }
        });

        return tableRows;
    }

    return {
        getTransactionLinesInUnitLoadGroupOrder,
        mapTransactionLinesForTable,
    };
}
