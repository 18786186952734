
import {
    defineComponent, reactive, onMounted, computed, watch, PropType,
} from 'vue';
import DateInput from '@/components/inputs/DateInput.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import coreStore from '@/store/CoreStore';
import Location from '@/domain/Location';
import LocationService from '@/services/LocationService';
import Transaction from '@/domain/Transaction';
import { TransactionStatus } from '@/domain/TransactionStatus';
import Shipment from '@/domain/Shipment';
import Carrier from '@/domain/Carrier';
import CarrierService from '@/modules/master-data/services/CarrierService';
import TrailerType from '@/domain/TrailerType';
import TrailerTypeService from '@/services/TrailerTypeService';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import RouteConfigService from '@/services/RouteConfigService';
import LocationRouteConfig from '@/domain/LocationRouteConfig';
import { emptyValidationResult } from '@/validation/useValidator';
import { ValidationResult } from '@/validation/types';
import NonWorkDayService from '@/services/NonWorkDayService';
import DropdownAutocompleteSingleSelect from '@/components/dropdown/DropdownAutocompleteSingleSelect.vue';
import useStringFormatter from '@/composable/useStringFormatter';
import BForm from '@/components/bootstrap-library/BForm.vue';
import BRow from '@/components/bootstrap-library/BRow.vue';
import BCol from '@/components/bootstrap-library/BCol.vue';
import Thumbnail from '@/components/Thumbnail.vue';
import ButtonUploadImage from '@/components/buttons/ButtonUploadImage.vue';
import { UploadImageEvent } from '@/domain/ImageUpload';
import { useNotification } from '@/composable/useNotifications';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';

type State = {
    shipToLocations: Array<Location>;
    carriers: Array<Carrier>;
    trailerTypes: Array<TrailerType>;
    loading: boolean;
    toLocationsLoading: boolean;
    deliveryDate: Date | null;
};

export default defineComponent({
    name: 'partner-engagement-transaction-create',
    components: {
        ButtonUploadImage,
        Thumbnail,
        BCol,
        BRow,
        BForm,
        DropdownAutocompleteSingleSelect,
        TextInput,
        DateInput,
    },
    props: {
        transaction: {
            type: Transaction,
            required: true,
        },
        shipment: {
            type: Shipment,
            required: true,
        },
        disputing: {
            type: Boolean,
            required: false,
        },
        shipmentValidationResult: {
            type: Object as PropType<ValidationResult<Shipment>>,
            default: () => emptyValidationResult,
        },
        transactionValidationResult: {
            type: Object as PropType<ValidationResult<Transaction>>,
            default: () => emptyValidationResult,
        },
    },
    emits: ['locationChange'],

    setup(props, context) {
        const { profileStore, timeSetStore, configStore } = coreStore.getInstance();

        const locationService = new LocationService();
        const carrierService = new CarrierService();
        const trailerService = new TrailerTypeService();
        const routeConfigService = new RouteConfigService();
        const nonWorkDayService = new NonWorkDayService(timeSetStore.allTimeSets);

        const { confirm } = useDialogBox();
        const { capitalizeFirstLetter } = useStringFormatter();

        const state = reactive<State>({
            shipToLocations: [],
            carriers: [],
            trailerTypes: [],
            loading: false,
            toLocationsLoading: false,
            deliveryDate: props.transaction.plannedDeliveryDate,
        });

        let routes: Array<LocationRouteConfig>;
        
        async function getCarrier(fromLocationId:number, toLocationId:number): Promise<Carrier | undefined> {
            const allRoutes = await routeConfigService.getRouteConfigsFromLocation(fromLocationId);
            const selectedRoute = allRoutes.find((route) => route.fromLocation.id === fromLocationId && route.toLocation.id === toLocationId);
            return state.carriers.find((carrier) => carrier.id === selectedRoute?.defaultCarrierId);
        }

        watch(
            () => [props.transaction.shipDate, props.transaction.fromLocation, props.transaction.toLocation],
            async ([newShipDate, newFrom, newTo], [oldShipDate, oldFrom, oldTo]) => {
                if (!props.transaction.shipDate || !props.transaction.fromLocation || !props.transaction.toLocation) {
                    return null;
                }

                // @ts-ignore
                if (newFrom?.id !== oldFrom?.id || newShipDate !== oldShipDate || !routes) {
                    routes = await routeConfigService.getRouteConfigsFromLocation(props.transaction.fromLocationId);
                    const transitTime = routes.find((x) => x.toLocation.id === props.transaction.toLocationId)?.transitTime || 0;
                    const millisecs = props.transaction.shipDate.getTime() + transitTime;
                    state.deliveryDate = new Date(millisecs);
                    state.deliveryDate = nonWorkDayService.getNextWorkingDay(state.deliveryDate, props.transaction.toLocation.nonWorkingTimeSetId);
                }
            },
        );

        if (props.transaction.shipDate) {
            props.transaction.shipDate = nonWorkDayService.getNextWorkingDay(props.transaction.shipDate, props.transaction.fromLocation.nonWorkingTimeSetId);
        }

        const isFieldDisabled = computed(() => !props.transaction.canEdit(profileStore.userLocation));

        const { newShipDateMin, newShipDateMax, newShipDateDisabled } = nonWorkDayService.getShipDateCalendarData(props.transaction.fromLocation.nonWorkingTimeSetId);

        onMounted(async () => {
            state.loading = true;

            // TODO: Carrier service is currently in master data. At some point,
            // we will have more logic than simply getting all carriers and that can be service calls and
            // a store unique to partner engagement module
            const carriers = await carrierService.getAllCarriers();

            if (carriers.success) {
                state.carriers = carriers.carriers;
                const carrier = await getCarrier(props.transaction.fromLocationId, props.transaction.toLocationId);
                props.shipment.carrier = carrier;
                props.shipment.carrierId = carrier?.id ?? 0;
            }
            // TODO: Trailer service is currently in master data. At some point,
            // we will have more logic than simply getting all trailers and that can be service calls and
            // a store unique to partner engagement module
            const trailerTypes = await trailerService.getAllTrailerTypes();
            if (trailerTypes.success) {
                state.trailerTypes = trailerTypes.trailerTypes;
            }

            if (carriers && trailerTypes) {
                state.loading = false;
            }

            if (props.transaction.fromLocation) {
                getShipToLocations(props.transaction.fromLocation);
            }
        });

        function selectCarrier(carrier: Carrier | null) {
            if (carrier) {
                props.shipment.carrierId = carrier.id;
                props.shipment.carrier = carrier;
            } else {
                props.shipment.carrierId = undefined;
                props.shipment.carrier = new Carrier();
            }
        }

        function selectTrailerType(trailerType: TrailerType) {
            props.shipment.trailerType = trailerType ?? new TrailerType();
        }

        function findLocationsByName(searchString: string) {
            const lowerCasedSearch = searchString.toLowerCase();
            return profileStore.locations.filter((location) => location.name.toLowerCase().includes(lowerCasedSearch) || location.shortName.toLowerCase().includes(lowerCasedSearch));
        }

        async function selectToLocation(loc: Location) {
            if (loc) {
                props.transaction.toLocation = loc;
                await trySetPreferredTrailerType();
                context.emit('locationChange');
            }
        }

        async function trySetPreferredTrailerType() {
            state.loading = true;
            let trailerType: TrailerType;
            if (props.transaction.toLocation?.id) {
                trailerType = (await trailerService.getPreferredTrailerTypeForRoute(props.transaction.fromLocation.id, props.transaction.toLocation.id)) ?? configStore.preferredTrailerType;
            } else {
                trailerType = new TrailerType();
            }
            selectTrailerType(trailerType);
            state.loading = false;
        }

        function clearToLocation() {
            props.transaction.toLocation = new Location();
            trySetPreferredTrailerType();
            context.emit('locationChange');
        }

        function getShipToLocations(selection: Location) {
            state.toLocationsLoading = true;
            if (selection) {
                const result = locationService
                    .getCanShipToLocations(selection.id)
                    .then((result) => {
                        if (result.success) {
                            state.shipToLocations = result.locations;
                        }
                    })
                    .finally(() => {
                        state.toLocationsLoading = false;
                    });
            } else {
                state.shipToLocations = [];
            }
        }

        async function beforeClearToLocation() {
            const response = await confirm({
                title: getTitleCaseTranslation('core.common.areYouSure'),
                message: capitalizeFirstLetter(getTranslation('core.common.clearLocationWarning')),
            });

            if (response) {
                clearToLocation();
            }
        }

        async function onUploadDisputeImage(value: UploadImageEvent) {
            const added = props.transaction.addDisputeImage(value);
            if (!added) {
                useNotification().showError('Image already associated with transaction.');
            }
        }

        return {
            findLocationsByName,
            getShipToLocations,
            state,
            selectToLocation,
            selectCarrier,
            selectTrailerType,
            TransactionStatus,
            isFieldDisabled,
            beforeClearToLocation,
            newShipDateMin,
            newShipDateMax,
            newShipDateDisabled,
            getTitleCaseTranslation,
            onUploadDisputeImage,
        };
    },
});
