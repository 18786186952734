import { ComputedRef, unref } from 'vue';
import { ItemTableData } from '@/domain/ItemTableData';
import TrackedItem from '@/domain/TrackedItem';
import Transaction from '@/domain/Transaction';
import TagScanHandler from './TagScanHandler';

export default class SupplierShippingTagScanHandler extends TagScanHandler {
    private transactionRef;

    private tableDataRef;

    constructor(transactionComputed: ComputedRef<Transaction>, tableDataComputed: ComputedRef<Array<ItemTableData>>) {
        super();
        this.transactionRef = transactionComputed;
        this.tableDataRef = tableDataComputed;
    }

    protected override async fetchTags(): Promise<Array<TrackedItem> | undefined> {
        return this.trackedItemService.searchTrackedItemsByBarcodes(this.scannerInput);
    }

    protected override handleScan(fetchedTags: Array<TrackedItem>): Array<string> {
        const transaction = unref(this.transactionRef);
        const tableData = unref(this.tableDataRef);

        const duplicates = this.scannerInput.filter((b) => transaction.tagList.includes(b));

        fetchedTags.forEach((tag) => {
            const tableRow = tableData.find((record) => record.item.id === tag.itemId);
            if (duplicates.find((barcode) => barcode === tag.barcode)) {
                return;
            }
            if (tableRow?.tags.find((x) => x.barcode === tag.barcode)) {
                duplicates.push(tag.barcode);
                return;
            }
            if (!tableData.find((row) => row.item.id === tag.item.id)) {
                this.notification.showWarning(`Tag: ${tag.barcode} can not be added to this shipment`);
                return;
            }
            transaction.addTrackedItem(tag);
            this.notification.showSuccess(`Tag: ${tag.barcode} added`);
        });

        return duplicates;
    }

    protected override handleScanManagedWarehouse(): Array<string> {
        return [];
    }
}
