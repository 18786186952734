import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_faicon = _resolveComponent("faicon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.canEdit)
      ? (_openBlock(), _createBlock(_component_b_button, {
          key: 0,
          disabled: _ctx.saving || _ctx.shipping || !_ctx.isDirty,
          variant: "primary",
          onClick: _ctx.submit
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.titleCase(_ctx.$t('core.button.save'))) + " ", 1),
            (_ctx.saving)
              ? (_openBlock(), _createBlock(_component_b_spinner, {
                  key: 0,
                  small: ""
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"]))
      : _createCommentVNode("", true),
    (_ctx.canShipNow)
      ? (_openBlock(), _createBlock(_component_b_button, {
          key: 1,
          disabled: _ctx.saving || _ctx.shipping,
          variant: "primary",
          onClick: _ctx.shipNow
        }, {
          default: _withCtx(() => [
            _createVNode(_component_faicon, { icon: "shipping-fast" }),
            _createTextVNode(" " + _toDisplayString(_ctx.titleCase(_ctx.$t('core.button.shipNow'))) + " ", 1),
            (_ctx.shipping)
              ? (_openBlock(), _createBlock(_component_b_spinner, {
                  key: 0,
                  small: ""
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"]))
      : _createCommentVNode("", true)
  ], 64))
}