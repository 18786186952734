import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62d9b009"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"display":"flex","height":"54px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_dropdown_autocomplete_single_select = _resolveComponent("dropdown-autocomplete-single-select")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_form_textarea = _resolveComponent("b-form-textarea")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_thumbnail = _resolveComponent("thumbnail")!
  const _component_button_upload_image = _resolveComponent("button-upload-image")!
  const _component_b_form = _resolveComponent("b-form")!

  return (_openBlock(), _createBlock(_component_b_form, null, {
    default: _withCtx(() => [
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_text_input, {
            modelValue: _ctx.transaction.fromLocation.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.transaction.fromLocation.name) = $event)),
            cols: "3",
            label: _ctx.getTitleCaseTranslation('core.domain.fromLocation'),
            disabled: "",
            class: "shipping-form-col"
          }, null, 8, ["modelValue", "label"]),
          _createVNode(_component_dropdown_autocomplete_single_select, {
            modelValue: _ctx.transaction.toLocation.name,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.transaction.toLocation.name) = $event)),
            loading: _ctx.state.toLocationsLoading,
            cols: "3",
            data: _ctx.state.shipToLocations,
            "search-by": "name",
            "on-before-clear": _ctx.beforeClearToLocation,
            disabled: _ctx.isFieldDisabled || _ctx.transaction.fromLocation.id === 0,
            "empty-text": "No locations associated",
            "display-name": "name",
            label: _ctx.getTitleCaseTranslation('core.domain.toLocation'),
            class: "shipping-form-col",
            error: _ctx.transactionValidationResult.model.toLocation,
            onOnSelect: _ctx.selectToLocation
          }, null, 8, ["modelValue", "loading", "data", "on-before-clear", "disabled", "label", "error", "onOnSelect"]),
          _createVNode(_component_date_input, {
            modelValue: _ctx.transaction.shipDate,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.transaction.shipDate) = $event)),
            disabled: _ctx.isFieldDisabled,
            "lower-limit": _ctx.newShipDateMin,
            "upper-limit": _ctx.newShipDateMax,
            "disabled-dates": _ctx.newShipDateDisabled,
            label: _ctx.getTitleCaseTranslation('core.domain.shipDate'),
            cols: "3",
            class: "shipping-form-col",
            error: _ctx.transactionValidationResult.model.shipDate
          }, null, 8, ["modelValue", "disabled", "lower-limit", "upper-limit", "disabled-dates", "label", "error"]),
          _createVNode(_component_date_input, {
            modelValue: _ctx.state.deliveryDate,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.deliveryDate) = $event)),
            disabled: true,
            label: _ctx.getTitleCaseTranslation('core.domain.plannedDeliveryDate'),
            cols: "3",
            class: "shipping-form-col"
          }, null, 8, ["modelValue", "label"])
        ]),
        _: 1
      }),
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_dropdown_autocomplete_single_select, {
            loading: _ctx.state.loading,
            cols: "3",
            disabled: _ctx.isFieldDisabled,
            "model-value": _ctx.shipment.carrierName,
            data: _ctx.state.carriers,
            "search-by": "name",
            "empty-text": "No carriers",
            "display-name": "name",
            label: _ctx.getTitleCaseTranslation('core.domain.carrier'),
            class: "shipping-form-col",
            error: _ctx.shipmentValidationResult.model.carrierId,
            onOnSelect: _ctx.selectCarrier
          }, null, 8, ["loading", "disabled", "model-value", "data", "label", "error", "onOnSelect"]),
          _createVNode(_component_text_input, {
            modelValue: _ctx.shipment.carrierReferenceNumber,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.shipment.carrierReferenceNumber) = $event)),
            disabled: _ctx.isFieldDisabled,
            placeholder: "Carrier Reference Number",
            label: _ctx.getTitleCaseTranslation('core.domain.carrierReferenceNumber'),
            cols: "3",
            class: "shipping-form-col",
            error: _ctx.shipmentValidationResult.model.carrierReferenceNumber
          }, null, 8, ["modelValue", "disabled", "label", "error"]),
          _createVNode(_component_text_input, {
            modelValue: _ctx.shipment.licensePlateNumber,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.shipment.licensePlateNumber) = $event)),
            disabled: _ctx.isFieldDisabled,
            label: _ctx.getTitleCaseTranslation('core.domain.licensePlateNumber'),
            placeholder: "License Plate Number",
            cols: "3",
            class: "shipping-form-col",
            error: _ctx.shipmentValidationResult.model.licensePlateNumber
          }, null, 8, ["modelValue", "disabled", "label", "error"]),
          _createVNode(_component_text_input, {
            modelValue: _ctx.transaction.partnerReferenceNumber,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.transaction.partnerReferenceNumber) = $event)),
            disabled: _ctx.isFieldDisabled,
            label: _ctx.getTitleCaseTranslation('core.domain.partnerReferenceNumber'),
            placeholder: "Partner Reference #",
            cols: "3",
            class: "shipping-form-col",
            error: _ctx.transactionValidationResult.model.partnerReferenceNumber
          }, null, 8, ["modelValue", "disabled", "label", "error"])
        ]),
        _: 1
      }),
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_dropdown_autocomplete_single_select, {
            loading: _ctx.state.loading,
            cols: "3",
            disabled: _ctx.isFieldDisabled,
            "model-value": _ctx.shipment.trailerTypeName,
            data: _ctx.state.trailerTypes,
            "search-by": "name",
            "empty-text": "No trailer types",
            "display-name": "name",
            label: _ctx.getTitleCaseTranslation('core.domain.trailerType'),
            class: "shipping-form-col",
            error: _ctx.shipmentValidationResult.model.trailerType,
            onOnSelect: _ctx.selectTrailerType
          }, null, 8, ["loading", "disabled", "model-value", "data", "label", "error", "onOnSelect"]),
          _createVNode(_component_text_input, {
            modelValue: _ctx.shipment.trailer,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.shipment.trailer) = $event)),
            disabled: _ctx.isFieldDisabled,
            label: _ctx.getTitleCaseTranslation('core.domain.trailerNumber'),
            placeholder: _ctx.getTitleCaseTranslation('core.domain.trailerNumber'),
            cols: "3",
            class: "shipping-form-col",
            error: _ctx.shipmentValidationResult.model.trailer
          }, null, 8, ["modelValue", "disabled", "label", "placeholder", "error"]),
          _createVNode(_component_text_input, {
            modelValue: _ctx.shipment.seal,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.shipment.seal) = $event)),
            label: _ctx.getTitleCaseTranslation('core.domain.sealNumberShort'),
            placeholder: _ctx.getTitleCaseTranslation('core.domain.sealNumber'),
            cols: "3",
            disabled: _ctx.isFieldDisabled,
            class: "shipping-form-col",
            error: _ctx.shipmentValidationResult.model.seal
          }, null, 8, ["modelValue", "label", "placeholder", "disabled", "error"])
        ]),
        _: 1
      }),
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, { cols: "6" }, {
            default: _withCtx(() => [
              _createVNode(_component_b_form_textarea, {
                id: "input-notes",
                modelValue: _ctx.shipment.deliveryNotes,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.shipment.deliveryNotes) = $event)),
                label: _ctx.getTitleCaseTranslation('core.domain.notes'),
                rows: "3",
                "max-length": 255
              }, null, 8, ["modelValue", "label"])
            ]),
            _: 1
          }),
          (_ctx.disputing)
            ? (_openBlock(), _createBlock(_component_text_input, {
                key: 0,
                modelValue: _ctx.transaction.disputeNotes,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.transaction.disputeNotes) = $event)),
                "text-area": "",
                disabled: _ctx.transaction.status === _ctx.TransactionStatus.DISPUTED,
                cols: "3",
                label: _ctx.getTitleCaseTranslation('core.domain.disputeNotes'),
                class: "shipping-form-col",
                error: _ctx.transactionValidationResult.model.disputeNotes
              }, null, 8, ["modelValue", "disabled", "label", "error"]))
            : _createCommentVNode("", true),
          (_ctx.disputing)
            ? (_openBlock(), _createBlock(_component_b_col, {
                key: 1,
                cols: "3",
                style: {"text-align":"left"}
              }, {
                default: _withCtx(() => [
                  _createElementVNode("label", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.disputeImages')), 1),
                  _createElementVNode("div", _hoisted_1, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.transaction.disputeImages, (image) => {
                      return (_openBlock(), _createBlock(_component_thumbnail, {
                        key: image.full,
                        "image-url-full": image.full,
                        "image-url-thumb": image.thumb,
                        "encoded-image": image.base64String,
                        "encoded-image-type": image.type,
                        style: {"margin":"0px 5px"}
                      }, null, 8, ["image-url-full", "image-url-thumb", "encoded-image", "encoded-image-type"]))
                    }), 128))
                  ]),
                  _createVNode(_component_b_row, { style: {"text-align":"left"} }, {
                    default: _withCtx(() => [
                      (_ctx.transaction.canUploadDisputeImage)
                        ? (_openBlock(), _createBlock(_component_button_upload_image, {
                            key: 0,
                            "button-text": _ctx.getTitleCaseTranslation('core.button.uploadImages'),
                            onOnUpload: _ctx.onUploadDisputeImage
                          }, null, 8, ["button-text", "onOnUpload"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}